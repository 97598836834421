<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      drawerTitle="Voucher detail"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <VoucherSummary
          v-if="showVoucherSummary"
          :voucherId="selectedVoucher.Id"
          :voucher="selectedVoucher"
          :locationId="locationId"
          @hideDrawer="hideDrawer"
        />
      </template>
    </ui-component-drawer>

    <ModalCreateVoucher
      v-if="showModalCreateVoucher"
      :showModal="showModalCreateVoucher"
      :onClickCancel="hideModal"
      :channelId="locationId > 0 ? 0 : channelId"
      :locationId="locationId"
      :voucherType="vouchersPageState.type"
      @voucherCreated="voucherCreated"
      :key="'cv' + timestamp"
    />

    <ModalDeleteVoucher
      v-if="showModalDeleteVoucher"
      :showModal="showModalDeleteVoucher"
      :onClickCancel="hideModal"
      :channelId="channelId"
      :locationId="locationId"
      :voucher="selectedVoucher"
      @voucherDeleted="voucherDeleted"
    />

    <div class="tabs is-boxed">
      <ul>
        <li
          v-for="(voucherType, index) in voucherTypes"
          :key="index"
          :class="{ 'is-active': vouchersPageState.type === voucherType.Id }"
        >
          <a @click="setVoucherType(voucherType.Id)">
            <span>{{ voucherType.Name }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="actionBar has-background-white-ter has-padding has-margin-bottom">
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <a
            @click="setShowCreateVoucher"
            class="has-icon"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </span>
            <span>{{
              $t('Views.Vouchers.Vouchers.Button_Create-voucher')
            }}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="navbar-menu">
      <div class="navbar-start">
        <!-- navbar items -->
      </div>

      <div class="navbar-end">
        <div class="field has-addons">
          <input
            type="text"
            v-model="vouchersPageState.searchTerm"
            v-on:keyup.13="searchVouchers"
            class="input is-small"
            placeholder="Search vouchers"
          />
          <button
            class="button is-primary is-small"
            :class="{ 'is-loading': isSearching }"
            @click="searchVouchers"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>

    <div>
      <ui-loader v-if="isSearching" />

      <Message
        v-if="!isSearching && hasLoaded && filteredVouchers.length === 0"
        :message="'No vouchers created'"
      />

      <table
        v-if="!isSearching && filteredVouchers.length > 0"
        class="table is-fullwidth is-striped"
      >
        <thead>
          <tr>
            <th>{{ $t('Views.Vouchers.Vouchers.Table_Name') }}</th>
            <th>Type</th>
            <th>{{ $t('Views.Vouchers.Vouchers.Table_Code') }}</th>
            <th>{{ $t('Views.Vouchers.Vouchers.Table_Valid-from') }}</th>
            <th>{{ $t('Views.Vouchers.Vouchers.Table_Valid-until') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="voucher in filteredVouchers"
            :key="voucher.Id"
          >
            <td>
              <a @click="setShowVoucherSummary(voucher)">{{ voucher.Name }}</a>
            </td>
            <td>{{ voucher.Type }}</td>
            <td>{{ voucher.Code }}</td>
            <td>{{ voucher.ValidFrom | dateObjectIsoDateString }}</td>
            <td>{{ voucher.ValidUntil | dateObjectIsoDateString }}</td>
            <td>
              <div class="field is-grouped is-grouped-right">
                <p class="control">
                  <a
                    @click="setShowVoucherSummary(voucher)"
                    :title="$t('Form.Control.ViewSummary')"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'info-circle']" />
                    </span>
                  </a>
                </p>
                <p class="control">
                  <a
                    @click="goToVoucher(voucher.Id)"
                    :title="$t('Form.Control.Edit')"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>
                </p>
                <p class="control">
                  <a
                    @click="setShowDeleteVoucher(voucher)"
                    :title="$t('Form.Control.Delete')"
                  >
                    <span class="icon has-text-danger">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        v-if="
          (!isSearching && vouchersPageState.morePages > 0) ||
            vouchersPageState.page > 1
        "
        :currentPage="vouchersPageState.page"
        :morePages="vouchersPageState.morePages"
        @pageSelected="pageSelected"
      />
    </div>
  </div>
</template>

<script>
import voucher from '@/providers/voucher'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    ModalCreateVoucher: () =>
      import('@/components/Vouchers/ModalCreateVoucher'),
    ModalDeleteVoucher: () =>
      import('@/components/Vouchers/ModalDeleteVoucher'),
    Message: () => import('@/components/UI/Message'),
    Pagination: () => import('@/components/UI/Pagination'),
    VoucherSummary: () => import('@/components/Vouchers/VoucherSummary'),
  },

  props: {
    pathType: {
      type: String,
      default: 'Channel'
    }
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      hasLoaded: false,
      isSearching: false,
      isSearchDone: false,
      selectedVoucher: null,
      showModalCreateVoucher: false,
      showModalDeleteVoucher: false,
      showSideDrawer: false,
      showVoucherSummary: false,
      timestamp: new Date().getTime(),
      vouchers: [],
      voucherTypes: [],
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapGetters({
      vouchersPageState: 'voucherStore/getVouchersPageState',
    }),

    filteredVouchers() {
      let self = this
      let voucherTypeIndex = self.voucherTypes.findIndex(
        (vt) => vt.Id === self.vouchersPageState.type
      )
      let voucherType = self.voucherTypes[voucherTypeIndex]

      if (self.vouchersPageState.type > -1) {
        return self.vouchersPageState.vouchers.filter(
          (v) => v.Type === voucherType.Name
        )
      }
      return self.vouchersPageState.vouchers
    },
  },

  created() {
    if (this.locationId === 0) {
      this.getChannelVouchers()
    } else {
      this.getLocationVouchers()
    }

    this.voucherTypes.push({ Id: -1, Name: 'All' })
    this.voucherTypes.push({ Id: 0, Name: 'Action' })
    this.voucherTypes.push({ Id: 1, Name: 'Package' })
    this.voucherTypes.push({ Id: 2, Name: 'Voucher' })
    this.voucherTypes.push({ Id: 3, Name: 'Rate' })
    this.voucherTypes.push({ Id: 4, Name: 'Credit' })
  },

  methods: {
    ...mapActions('voucherStore', ['setVouchersPageState']),

    setVoucherType(type) {
      this.vouchersPageState.type = type
      this.setVouchersPageState(this.vouchersPageState)
    },

    getChannelVouchers() {
      let self = this

      self.hasLoaded = true
      self.isSearching = true
      self.vouchersPageState.vouchers = []

      voucher.methods
        .getChannelVouchers(
          self.channelId,
          self.vouchersPageState.page,
          self.vouchersPageState.itemsPerPage,
          self.vouchersPageState.searchTerm,
          self.vouchersPageState.type
        )
        .then((response) => {
          if (response.status === 200) {
            self.vouchersPageState.vouchers = response.data.Results
            self.vouchersPageState.morePages = response.data.MorePages

            self.setVouchersPageState(self.vouchersPageState)
          }
        })
        .finally(() => {
          self.isSearching = false
          self.isSearchDone = true
        })
    },

    getLocationVouchers() {
      let self = this

      self.hasLoaded = true
      self.isSearching = true
      self.vouchersPageState.vouchers = []

      voucher.methods
        .getLocationVouchers(
          self.locationId,
          self.vouchersPageState.page,
          self.vouchersPageState.itemsPerPage,
          self.vouchersPageState.searchTerm,
          self.vouchersPageState.type
        )
        .then((response) => {
          if (response.status === 200) {
            self.vouchersPageState.vouchers = response.data.Results
            self.vouchersPageState.morePages = response.data.MorePages

            self.setVouchersPageState(self.vouchersPageState)
          }
        })
        .finally(() => {
          self.isSearching = false
          self.isSearchDone = true
        })
    },

    searchVouchers() {
      let self = this
      self.vouchersPageState.vouchers = []
      self.vouchersPageState.page = 1

      //self.vouchersPageState.searchTerm = searchTerm

      self.getChannelVouchers()
    },

    pageSelected(page) {
      let self = this
      self.vouchersPageState.vouchers = []
      self.vouchersPageState.page = page

      self.getChannelVouchers()
    },

    voucherCreated(newVoucher) {
      //this.vouchersPageState.vouchers.push(newVoucher)
      this.timestamp = new Date().getTime()
      this.goToVoucher(newVoucher.Id)
    },

    voucherDeleted() {
      if (this.locationId === 0) {
        this.getChannelVouchers()
      } else {
        this.getLocationVouchers()
      }
    },

    setShowCreateVoucher() {
      this.showModalCreateVoucher = true
    },

    setShowDeleteVoucher(voucher) {
      this.showModalDeleteVoucher = true
      this.selectedVoucher = voucher
    },

    setShowVouchers() {
      this.setVisibleItem('vouchers')
    },

    setShowVoucherSummary(voucher) {
      this.selectedVoucher = voucher

      this.showSideDrawer = true
      this.showVoucherSummary = true
    },

    hideModal() {
      this.showModalCreateVoucher = false
      this.showModalDeleteVoucher = false

      this.modalTitle = ''
      this.selectedVoucher = null
    },

    goToVoucher(voucherId) {
      if (this.locationId > 0) {
        this.$router.push({
          name: `location-vouchers-detail`,
          params: { voucherId: voucherId },
        })
      } else {
        this.$router.push({
          name: `vouchers-detail`,
          params: { voucherId: voucherId },
        })
      }
    },

    hideDrawer() {
      this.showSideDrawer = false
      this.showVoucherSummary = false
    },
  },
}
</script>

<style></style>
